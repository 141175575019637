import React from 'react';
import { redirect, logAnalytics } from '../util';
import mascot from '../assets/hq/mascot.svg';
import { RouteNames } from '../constants/routeNames';
import { RedirectURLs } from '../constants/redirectURLS';
import { navigate } from 'gatsby';

export default function FooterSection() {
  return (
    <footer className='mb-[52px] w-full'>
      <hr className='bg-[#D9D9D999] h-[1px] w-full mt-[180px]' />
      <div className='mt-[46px] mb-[80px] md:flex justify-evenly w-[86%] ml-[8%]'>
        <div>
          <div className='flex'>
            <img
              src={mascot}
              alt='Cypher Mascot'
              title='Cypher Mascot'
              width='50'
              height='50'
            />
            <div className='ml-[5px]'>
              <h1 className='text-[28px] font-bold'>{'Cypher'}</h1>
              <p className='font-medium text-[12px] text-[#555555]'>
                {'CypherD Wallet Inc (6380568).'}
              </p>
            </div>
          </div>
          <p className='hidden md:block font-medium text-[12px] text-[#666666]'>
            {'Crafted in 🇮🇳, for all humankind.'}
          </p>
        </div>
        <div className='grid sm:grid-rows-2 lg:grid-rows-1 sm:gap-y-[28px] lg:grid-cols-3 lg:gap-x-[75px]'>
          <div>
            <h2 className='text-[13px] font-medium mt-[24px] text-[#666] cursor-pointer'>
              {'Links'}
            </h2>
            <p
              className='text-base font-medium mt-[16px] cursor-pointer'
              onClick={() => {
                redirect('https://developer.cypherwallet.io/');
                logAnalytics({
                  category: 'footer',
                  action: 'click link to developer docs',
                  label: 'developers',
                });
              }}
            >
              {'Developers'}
            </p>
            <p
              className='text-base font-medium mt-[16px] cursor-pointer'
              onClick={() => {
                redirect(RouteNames.faq);
                logAnalytics({
                  category: 'footer',
                  action: 'click link to faq',
                  label: 'faq',
                });
              }}
            >
              {'FAQ’s'}
            </p>
            <p
              className='text-base font-medium mt-[16px] cursor-pointer'
              onClick={() => {
                redirect('https://medium.com/@CypherWallet');
                logAnalytics({
                  category: 'footer',
                  action: 'click link to medium blog',
                  label: 'blog',
                });
              }}
            >
              {'Blog'}
            </p>
            <p
              className='text-base font-medium mt-[16px] cursor-pointer'
              onClick={() => {
                navigate('/contact');
                logAnalytics({
                  category: 'footer',
                  action: 'click link to medium blog',
                  label: 'blog',
                });
              }}
            >
              {'Contact Us'}
            </p>
          </div>
          <div>
            <h2 className='text-[13px] font-medium mt-[24px] text-[#666]'>
              {'Solutions'}
            </h2>
            <p
              className='text-base font-medium mt-[16px] cursor-pointer'
              onClick={() => {
                redirect(RedirectURLs.dApp);
                logAnalytics({
                  category: 'footer',
                  action: 'click link to dapp',
                  label: 'cypher wallet',
                });
              }}
            >
              {'Cypher Wallet'}
            </p>
            <p
              className='text-base font-medium mt-[16px] cursor-pointer'
              onClick={() => {
                redirect(RedirectURLs.business);
                logAnalytics({
                  category: 'footer',
                  action: 'click link to business',
                  label: 'cypher business',
                });
              }}
            >
              {'Cypher Business'}
            </p>
          </div>
          <div>
            <h2 className='text-[13px] font-medium mt-[24px] text-[#666]'>
              {'Legal'}
            </h2>
            <p
              className='text-base font-medium mt-[16px] cursor-pointer'
              onClick={() => {
                navigate(RouteNames.legal);
                logAnalytics({
                  category: 'footer',
                  action: 'click link to legal',
                  label: 'cypher wallet',
                });
              }}
            >
              {'Terms Of Use'}
            </p>
          </div>
        </div>
      </div>
      <hr className='invisible md:visible bg-[#1F1F1F] h-[1px] w-full' />
      <div className='flex flex-col-reverse md:flex-row md:justify-evenly'>
        <div>
          <p className='md:hidden font-medium text-[12px] text-[#666666] mt-4 text-center'>
            {'Crafted in 🇮🇳, for all humankind.'}
          </p>
          <p className='font-normal text-[12px] text-[#666] md:text-black md:text-base md:my-[20px] text-center md:text-left'>
            {
              'Copyright © 2024 CypherD Wallet Inc (6380568). All rights reserved.'
            }
          </p>
        </div>
        <div className='grid grid-cols-3'>
          <div
            className='border md:border-l-[#1F1F1F] px-4 py-5 flex items-start justify-center cursor-pointer'
            onClick={() => {
              redirect(RedirectURLs.discord);
              logAnalytics({
                category: 'footer',
                action: 'click link to discord',
                label: 'discord',
              });
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='31'
              height='31'
              viewBox='0 0 31 31'
              fill='none'
            >
              <path
                d='M18.8182 5.50293C18.5617 5.96236 18.3331 6.43683 18.1337 6.92376C16.1739 6.61383 14.1776 6.61383 12.2178 6.92376C12.0184 6.43683 11.7898 5.96236 11.5332 5.50293C9.68717 5.81836 7.89255 6.38325 6.19865 7.1821C3.16962 11.5535 1.79592 16.8605 2.32365 22.1525C4.29763 23.6365 6.51312 24.7682 8.8724 25.4979C9.40962 24.7883 9.88908 24.0367 10.3062 23.2504C9.53641 22.9666 8.79658 22.6075 8.0974 22.1783C8.28916 22.0508 8.47056 21.9082 8.6399 21.7521C10.6787 22.7342 12.9127 23.2442 15.1757 23.2442C17.4388 23.2442 19.6727 22.7342 21.7116 21.7521C21.8924 21.9071 22.0732 22.0492 22.2541 22.1783C21.5511 22.6041 20.812 22.9672 20.0453 23.2633C20.4451 24.0675 20.9117 24.8366 21.4403 25.5625C23.7967 24.8353 26.0083 23.7033 27.9762 22.2171C28.5166 16.9241 27.1417 11.6127 24.1012 7.24668C22.4263 6.4313 20.6494 5.8448 18.8182 5.50293ZM10.8874 19.13C10.241 19.0836 9.63777 18.7886 9.20426 18.3069C8.77075 17.8252 8.5407 17.1944 8.5624 16.5467C8.53742 15.8982 8.76634 15.2655 9.2005 14.7831C9.63466 14.3006 10.2398 14.0066 10.8874 13.9633C11.535 14.0066 12.1401 14.3006 12.5743 14.7831C13.0085 15.2655 13.2374 15.8982 13.2124 16.5467C13.2374 17.1952 13.0085 17.8279 12.5743 18.3103C12.1401 18.7927 11.535 19.0868 10.8874 19.13ZM19.4641 19.13C18.8177 19.0836 18.2144 18.7886 17.7809 18.3069C17.3474 17.8252 17.1174 17.1944 17.1391 16.5467C17.1141 15.8982 17.343 15.2655 17.7772 14.7831C18.2113 14.3006 18.8165 14.0066 19.4641 13.9633C20.1128 14.0034 20.72 14.2965 21.1548 14.7796C21.5896 15.2628 21.8174 15.8973 21.7891 16.5467C21.8174 17.1961 21.5896 17.8306 21.1548 18.3137C20.72 18.7969 20.1128 19.09 19.4641 19.13Z'
                fill='#999999'
              />
            </svg>
          </div>
          <div
            className='border sm:border-x-[#1F1F1F] md:border-l-[#1F1F1F] px-4 py-5 flex items-start justify-center cursor-pointer'
            onClick={() => {
              redirect(RedirectURLs.twitter);
              logAnalytics({
                category: 'footer',
                action: 'click link to twitter',
                label: 'twitter',
              });
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
            >
              <g clipPath='url(#clip0_120_1832)'>
                <path
                  d='M19.6426 1H23.3052L15.3052 10.2048L24.751 22.6867H17.3293L11.5462 15.1205L4.89556 22.6867H1.2329L9.81122 12.8554L0.750977 1H8.36544L13.6185 7.93976L19.6426 1ZM18.3414 20.4699H20.3655L7.257 3.07229H5.04014L18.3414 20.4699Z'
                  fill='black'
                  fillOpacity='0.4'
                />
              </g>
              <defs>
                <clipPath id='clip0_120_1832'>
                  <rect
                    width='24'
                    height='24'
                    fill='white'
                    transform='translate(0.750977)'
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            className='border md:border-r-[#1F1F1F] px-4 py-5 flex items-start justify-center cursor-pointer'
            onClick={() => {
              redirect(RedirectURLs.linkedin);
              logAnalytics({
                category: 'footer',
                action: 'click link to linkedin',
                label: 'link to linkedin',
              });
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
            >
              <g clipPath='url(#clip0_120_1835)'>
                <mask
                  id='mask0_120_1835'
                  style={{ maskType: 'luminance' }}
                  maskUnits='userSpaceOnUse'
                  x='0'
                  y='0'
                  width='25'
                  height='24'
                >
                  <path d='M24.751 0H0.750977V24H24.751V0Z' fill='white' />
                </mask>
                <g mask='url(#mask0_120_1835)'>
                  <path
                    d='M21.1736 20.2213H17.6222V14.7144C17.6222 13.4012 17.5985 11.7107 15.7751 11.7107C13.9254 11.7107 13.6424 13.1415 13.6424 14.6188V20.2209H10.0909V8.89645H13.5003V10.4441H13.548C13.8892 9.86642 14.3823 9.39123 14.9747 9.06909C15.567 8.74696 16.2366 8.58994 16.9119 8.61476C20.5115 8.61476 21.1751 10.9591 21.1751 14.0089L21.1736 20.2213ZM6.08369 7.34847C5.67607 7.34854 5.27758 7.22893 4.93862 7.00476C4.59965 6.78059 4.33546 6.46193 4.1794 6.08908C4.02335 5.71623 3.98245 5.30593 4.0619 4.91007C4.14135 4.51421 4.33758 4.15056 4.62575 3.86512C4.91393 3.57968 5.28112 3.38526 5.68089 3.30645C6.08066 3.22764 6.49505 3.26798 6.87167 3.42236C7.24828 3.57675 7.57021 3.83825 7.79673 4.17379C8.02325 4.50933 8.1442 4.90385 8.14427 5.30745C8.14432 5.57544 8.09105 5.8408 7.98752 6.0884C7.884 6.336 7.73224 6.56099 7.5409 6.75052C7.34956 6.94004 7.12237 7.0904 6.87235 7.19299C6.62232 7.29559 6.35434 7.34842 6.08369 7.34847ZM7.85941 20.2213H4.30427V8.89645H7.85941V20.2213ZM22.9442 0.0016169H2.51968C2.0561 -0.00356306 1.60938 0.173702 1.27773 0.49446C0.946086 0.815217 0.756634 1.25322 0.750977 1.71223V22.0195C0.756441 22.4787 0.945781 22.917 1.27742 23.2381C1.60905 23.5592 2.05587 23.7368 2.51968 23.7319H22.9442C23.4089 23.7377 23.857 23.5606 24.1899 23.2395C24.5229 22.9184 24.7135 22.4796 24.7199 22.0195V1.71077C24.7133 1.25085 24.5225 0.812343 24.1896 0.491594C23.8566 0.170844 23.4087 -0.00591465 22.9442 0.000151116'
                    fill='black'
                    fillOpacity='0.4'
                  />
                </g>
              </g>
              <defs>
                <clipPath id='clip0_120_1835'>
                  <rect
                    width='24'
                    height='24'
                    fill='white'
                    transform='translate(0.750977)'
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <hr className='invisible md:visible bg-[#1F1F1F] h-[1px] w-full' />
    </footer>
  );
}
