export const RedirectURLs = {
  dApp: 'https://app.cypherhq.io/#/card',
  business: 'https://business.cypherhq.io',
  appStore: 'https://apps.apple.com/us/app/cypherd-wallet/id1604120414',
  playStore:
    'https://play.google.com/store/apps/details?id=com.cypherd.androidwallet',
  extension:
    'https://chrome.google.com/webstore/detail/cypher-wallet/niiaamnmgebpeejeemoifgdndgeaekhe',
  linkedin: 'https://www.linkedin.com/company/cypher-wallet',
  twitter: 'https://x.com/Cypher_HQ_',
  discord: 'https://discord.com/invite/S9tDGZ9hgT',
  reddit: 'https://www.reddit.com/r/CypherWallet/',
  youtube: 'https://www.youtube.com/@Cypherhq_io',
  medium: '',
  dAppConnectWallet: 'https://app.cypherhq.io',
  dAppTrackWallet: 'https://app.cypherhq.io/?trackWallet=',
  businessSignup: 'https://business.cypherhq.io/app/signup',
  cardPlan: 'https://app.cypherhq.io/#/card/plans',
};
