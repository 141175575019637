import React from 'react';
import { navigate } from 'gatsby';
import { Modal } from 'antd';
import { useIsBrowser } from '../hooks/useIsBrowser';

export default function HamurgerDropdown({ open, setOpen }) {
  const isBrowser = useIsBrowser();

  const redirectUser = () => {
    if (isBrowser.current) {
      if (navigator.userAgent.indexOf('Android') !== -1) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.cypherd.androidwallet';
      } else if (navigator.userAgent.indexOf('like Mac') !== -1) {
        window.location.href =
          'https://apps.apple.com/in/app/cypherd-wallet/id1604120414';
      } else {
        window.location.href = 'https://cypherd.io/';
      }
    }
  };

  return (
    <Modal
      open={open}
      title={null}
      onCancel={() => setOpen(false)}
      footer={null}
      className='rounded-[12px] border border-[#D9D9D9] p-0'
      style={{
        background:
          'linear-gradient(90deg, rgba(255, 229, 136, 0.30) 0%, rgba(188, 102, 190, 0.30) 58.93%, rgba(153, 137, 82, 0.30) 100%)',
        backdropFilter: 'blur(15px)',
      }}
    >
      <div className='py-[16px] px-[21px]'>
        <p className=' font-medium text-[14px]'>{'Consumer '}</p>
        <div
          className='flex items-center space-x-2 pl-6'
          onClick={() => {
            redirectUser();
            setOpen(false);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='#000'
            version='1.1'
            viewBox='0 0 330 330'
            xmlSpace='preserve'
          >
            <path d='M325.607 229.394l-75-75A15.002 15.002 0 00225 165v60H30V15c0-8.284-6.716-15-15-15S0 6.716 0 15v225c0 8.284 6.716 15 15 15h210v60a14.998 14.998 0 0014.997 15c3.903 0 7.74-1.524 10.609-4.394l75-75c5.859-5.857 5.859-15.355.001-21.212zM255 278.787v-77.573L293.787 240 255 278.787z'></path>
          </svg>
          <p className=' mt-2 font-medium text-[14px]'>{'Cypher Wallet'}</p>
        </div>
        <div
          className='flex items-center space-x-2 pl-6'
          onClick={() => {
            navigate('/dapp');
            setOpen(false);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='#000'
            version='1.1'
            viewBox='0 0 330 330'
            xmlSpace='preserve'
          >
            <path d='M325.607 229.394l-75-75A15.002 15.002 0 00225 165v60H30V15c0-8.284-6.716-15-15-15S0 6.716 0 15v225c0 8.284 6.716 15 15 15h210v60a14.998 14.998 0 0014.997 15c3.903 0 7.74-1.524 10.609-4.394l75-75c5.859-5.857 5.859-15.355.001-21.212zM255 278.787v-77.573L293.787 240 255 278.787z'></path>
          </svg>
          <p className='mt-2 font-medium text-[14px]'>{'Cypher Dapp'}</p>
        </div>
      </div>
      <div className='bg-[#E5E5E5] w-full h-[1px]' />
      <p
        className='py-[16px] px-[21px] font-medium text-[14px]'
        onClick={() => navigate('/business')}
      >
        {'Business '}
      </p>
      <div className='bg-[#E5E5E5] w-full h-[1px]' />
      <div className='py-[16px] px-[21px]'>
        <p className=' font-medium text-[14px]'>{'Cypher Card'}</p>
        <div
          className='flex items-center space-x-2 pl-6'
          onClick={() => {
            navigate('/card');
            setOpen(false);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='#000'
            version='1.1'
            viewBox='0 0 330 330'
            xmlSpace='preserve'
          >
            <path d='M325.607 229.394l-75-75A15.002 15.002 0 00225 165v60H30V15c0-8.284-6.716-15-15-15S0 6.716 0 15v225c0 8.284 6.716 15 15 15h210v60a14.998 14.998 0 0014.997 15c3.903 0 7.74-1.524 10.609-4.394l75-75c5.859-5.857 5.859-15.355.001-21.212zM255 278.787v-77.573L293.787 240 255 278.787z'></path>
          </svg>
          <p className=' mt-2 font-medium text-[14px]'>{'Cards'}</p>
        </div>
        <div
          className='flex items-center space-x-2 pl-6'
          onClick={() => {
            navigate('/card/#plans');
            setOpen(false);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='#000'
            version='1.1'
            viewBox='0 0 330 330'
            xmlSpace='preserve'
          >
            <path d='M325.607 229.394l-75-75A15.002 15.002 0 00225 165v60H30V15c0-8.284-6.716-15-15-15S0 6.716 0 15v225c0 8.284 6.716 15 15 15h210v60a14.998 14.998 0 0014.997 15c3.903 0 7.74-1.524 10.609-4.394l75-75c5.859-5.857 5.859-15.355.001-21.212zM255 278.787v-77.573L293.787 240 255 278.787z'></path>
          </svg>
          <p className='mt-2 font-medium text-[14px]'>{'Pricing'}</p>
        </div>
      </div>
      {/* <div className='bg-[#E5E5E5] w-full h-[1px]' /> */}
      {/* <p className='py-[16px] px-[21px] font-medium text-[14px]'>
        {'About Us'}
      </p> */}
      <div className='bg-[#E5E5E5] w-full h-[1px]' />
      <div className='flex items-center space-x-1 pt-[16px] px-[21px]'>
        <p
          className='text-[14px] font-medium'
          onClick={() => {
            if (isBrowser.current) {
              window.open('https://developer.cypherwallet.io/', '_blank');
            }
          }}
        >
          {'Developers'}
        </p>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='11'
          height='10'
          viewBox='0 0 11 10'
          fill='none'
        >
          <path
            d='M8.56229 2.97291L1.8496 9.68562L0.746582 8.58261L7.45928 1.8699H1.54277V0.309998H10.1222V8.88945H8.56229V2.97291Z'
            fill='black'
          />
        </svg>
      </div>
    </Modal>
  );
}
